import axios from "axios";

console.log("axiosCreate", window.myVariable);
if (window.myVariable == undefined) {
  window.myVariable = "https://act24ser.loca.lt/tutorials";
}
export default axios.create({
  //baseURL: "https://act24ser.loca.lt/tutorials",
  baseURL: window.myVariable,
  headers: {
    "Content-type": "application/json",
    "ngrok-skip-browser-warning": 1,
    "Bypass-Tunnel-Reminder": 3,
    "bypass-tunnel-reminder": 1,
    "X-TOKEN": "keylogsch24",
    "Access-Control-Allow-Origin": "*",
  },
});
